import React from "react";
import { Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/Contact.css";

const Contact = () => {
  return (
    <Container className="contact-container vh-100">
      <div className="well">
        <h1 className="text-center mb-4">Contact Us</h1>
        <p className="lead">
          We are currently looking for a home dojo for the time being
        </p>
        <p className="lead">
          Please contact Sensei Michel Hovan at <a href="mailto:mhovan4@mac.com">mhovan4@mac.com</a> or Mike Kaelin at <a href="mailto:Mike@kaelin.net">Mike@kaelin.net</a> for questions.
        </p>
        <p className="lead">
          We look forward to seeing you soon. The first class is free of charge. Please contact us prior to let us know you will be training so we can make sure that you are updated on any changes to the schedule.
        </p>
      </div>
    </Container>
  );
};

export default Contact;

