import React from "react";
import { Link } from "react-router-dom";
import { Navbar, Nav } from 'react-bootstrap';
import logoImage from "../assets/GS_Aikido_Header.jpeg"; // replace with the path to your image
import 'bootstrap/dist/css/bootstrap.min.css';
import "./styles/Header.css";

const Header = () => {
  return (
    <Navbar className="custom-navbar" variant="dark" expand="lg" sticky="top">
      <Navbar.Brand as={Link} to="/">
        <img src={logoImage} alt="Logo" className="header-logo" />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          <Nav.Link className="nav-link" as={Link} to="/">Home</Nav.Link>  
          <Nav.Link className="nav-link" as={Link} to="/classes">Classes</Nav.Link>
          <Nav.Link className="nav-link" as={Link} to="/about">About Us</Nav.Link>
          <Nav.Link className="nav-link" as={Link} to="/contact">Contact</Nav.Link>
          <Nav.Link className="nav-link" as={Link} to="/schedule">Schedule</Nav.Link>
          <Nav.Link className="nav-link" as={Link} to="/FAQs">FAQs</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;
