import React from "react";
import "./styles/AboutUsSec.css";

const AboutUsSec = () => {
    return (
        <section className="AboutUsSec">
        <h2 style={{ textAlign: "center", fontWeight: "bold"}}>About Us</h2>
        <p>
            Our dojo, nestled in the beautiful setting of New Hampshire, is a
            community of Aikido practitioners committed to preserving and
            promoting the principles of this unique martial art. We welcome
            people of all ages and physical abilities, with no previous
            martial arts training required.
        </p>
        </section>
    );
};

export default AboutUsSec;