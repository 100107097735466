import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./styles/AboutUs.css";
import JM_BlackBelt from "../assets/aboutus/michel/JM_BB.jpg";
import ImageCarousel from "../Components/ImageCarousel";

const images = require.context("../assets/aboutus", false, /\.(jpeg|jpg|png)$/);
const imagePaths = images.keys();
const imageArray = imagePaths.map((path) => images(path));

const AboutUs = () => {
  const [isTextVisible, setIsTextVisible] = useState(true);

  const handleHeaderClick = () => {
    setIsTextVisible(!isTextVisible);
  };

  return (
    <div className="aboutus-section">
      <Container className="fluid px-3 py-3">
        <Row>
          <Col xs={12} md={6}>
            <h1 style={{ fontSize: "3rem", fontWeight: "bold", color: "#333" }}>
              About Us
            </h1>
            <div className="aboutus-text">
              <p className="lead">
                Our instructors are experienced martial artists who are
                passionate about Aikido and dedicated to teaching its techniques
                and philosophy to our students. We offer a variety of classes
                for all skill levels, from beginner to advanced, and we
                encourage a spirit of camaraderie and mutual respect among our
                students.
              </p>

              <h2
                onClick={handleHeaderClick}
                style={{ fontSize: "2rem", fontWeight: "bold", color: "#333" }}
              >
                Sensei Michel Hovan, 6th Dan, Shidoin{" "}
                <span style={{ fontsize: "1rem", color: "#999" }}>
                  {isTextVisible ? "↑ (Hide Text)" : "↓ (Show Text)"}
                </span>
              </h2>
              {isTextVisible && (
                <>
                  <p className="lead">
                    I started Aikido in 1981 in Paris, France, where I studied
                    the art until 1983. I then lived in Winnipeg for two years
                    but Aikido practice was limited those early days. I then
                    moved to Minneapolis, Minnesota, and restarted my practice
                    at the Twin Cities Aikido Center. My Kyu ranks were obtained
                    under Akira Tohei Shihan (Chicago). In 1993 my job relocated
                    me to Southern New Jersey. In 1995, I had the blessing of
                    meeting Donovan Waite Shihan at a seminar in Philadelphia.
                    From that time I commuted weekly, on Saturdays, to the New
                    York Aikikai to study under Waite Shihan. He subsequently
                    moved to Philadelphia in 1997 and opened Aikido of Center
                    City. My wife and I became his students and started training
                    several hours and several times a week. My Dan ranks were
                    obtained under Waite Shihan and Yamada Shihan. In 2006, my
                    job moved me to New Hampshire where I have been trying to
                    pass on all the teachings that have been given to me over
                    the years. I was designated as a Shidoin in 2017.
                  </p>
                  <p className="lead">
                    A few years ago, I started practicing Brazilian Jiu Jitsu
                    and in 2023 I was promoted to the black belt level. In many
                    ways, I find it very similar and complementary to Aikido as
                    one searches for techniques that can be applied with minimum
                    strength. I also enjoy other arts such as wrestling, Judo,
                    and Wing Chun.
                  </p>
                  <p className="lead">
                    In case you like water, I am also a scuba instructor who
                    loves to take people out.
                  </p>
                </>
              )}
            </div>
          </Col>
          <Col
            xs={12}
            md={6}
            className="d-flex flex-column justify-content-center align-items-center"
          >
            <div className="mb-3">
              <img
                src={JM_BlackBelt}
                alt="Sensei Michel Hovan"
                className="img-fluid rounded p-4 m-2 jm-blackbelt-image"
              />
            </div>
            <div>
              <ImageCarousel images={imageArray} showButtons={false} />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AboutUs;
