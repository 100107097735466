import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./styles/WelcomeSec.css";
import "bootstrap/dist/css/bootstrap.min.css";

const WelcomeSec = () => {
  return (
    <section className="welcome-section">
      <Container>
        <Row>
          <Col>
            <h2 className="text-center mb-4" style={{ fontWeight: "bold" }}>
              Welcome to Aikido Granite State
            </h2>
            <p className="lead" style={{ fontSize: "1.4em" }}>
              Join our Aikido community, dedicated to upholding and sharing this
              distinctive martial art's principles. Whether a seasoned martial
              artist or a beginner, embark with us on a path of continuous
              learning and growth.
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default WelcomeSec;
