import React from "react";
import "./styles/Classes.css";


const Classes = () => {
  return (
    <div className="classes-page">
      <h1 style={{ textAlign: "center" }}>Our Classes</h1>
      <div className="class-level">
        <h2>Beginner</h2>
        <p>
          Our beginner classes focus on the fundamentals of Aikido, including basic techniques, falls, and etiquette. No prior martial arts experience is necessary.
        </p>
      </div>
      <div className="class-level">
        <h2>Intermediate</h2>
        <p>
          Intermediate classes build on the basics with more complex techniques and combinations. Some prior experience in Aikido or another martial art is recommended.
        </p>
      </div>
      <div className="class-level">
        <h2>Advanced</h2>
        <p>
          Advanced classes are for students who have mastered the fundamentals and are ready for more challenging techniques and longer, more intense training sessions.
        </p>
      </div>
      <div className="class-level">
        <h2>Children's Classes</h2>
        <p>
          We offer classes specifically for children, focusing on fun, safety, and basic Aikido techniques. These classes help kids build confidence, discipline, and respect for others.
        </p>
      </div>
    </div>
  );
};

export default Classes;
