import React from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import "./styles/Hero.css";
import ContactForm from "./ContactForm";
import { useLocation } from "react-router-dom";

const Hero = () => {
  const location = useLocation();
  const [showModal, setShowModal] = React.useState(false);

  const toggleModal = () => setShowModal(!showModal);

  if (location.pathname !== "/") {
    return null;
  }
  return (
    <Container
      fluid
      className="hero"
    >
      <Row className="hero-content d-flex justify-content-center align-items-center">
        <Col
          xs={12}
          className="d-flex justify-content-center hero-button-container"
        >
          <Button className="hero-button text-center btn btn-primary" onClick={toggleModal}>
            Begin your Aikido Journey Now
          </Button>
        </Col>
      </Row>

      <ContactForm showModal={showModal} toggleModal={toggleModal} />
    </Container>
  );
};

export default Hero;