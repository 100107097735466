// ContactForm.js
import React from "react";
import { Button, Modal, Form } from "react-bootstrap";
import "./styles/ContactForm.css";

const ContactForm = ({ showModal, toggleModal }) => {
  const modalStyle = {
    marginTop: "15vh", // adjust as needed
  };

  return (
    <Modal show={showModal} onHide={toggleModal} style={modalStyle}>
      <Modal.Header closeButton>
        <Modal.Title>Interest Form</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group>
            <Form.Label>Name:</Form.Label>
            <Form.Control type="text" name="name" />
          </Form.Group>
          <Form.Group>
            <Form.Label>Email:</Form.Label>
            <Form.Control type="email" name="email" required/>
          </Form.Group>
          <Form.Group>
            <Form.Label>Contact Details:</Form.Label>
            <Form.Control type="text" name="contactDetails" />
          </Form.Group>
          <Form.Group>
            <Form.Label>Any Further Questions:</Form.Label>
            <Form.Control as="textarea" name="reasonAndQuestions" />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={toggleModal}>
          Close
        </Button>
        <Button variant="primary" onClick={toggleModal} className="ml-2">
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};


export default ContactForm;
