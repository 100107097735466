import React, { useState, useEffect, useCallback } from "react";
import "./styles/ImageCarousel.css";

const ImageCarousel = ({ images, showButtons, random = false }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const nextSlide = useCallback(() => {
    if (random) {
      const randomIndex = Math.floor(Math.random() * images.length);
      setCurrentImageIndex(randomIndex);
    } else {
      const index = (currentImageIndex + 1) % images.length;
      setCurrentImageIndex(index);
    }
  }, [currentImageIndex, images.length, random]);

  const prevSlide = useCallback(() => {
    if (random) {
      const randomIndex = Math.floor(Math.random() * images.length);
      setCurrentImageIndex(randomIndex);
    } else {
      const index = (currentImageIndex - 1 + images.length) % images.length;
      setCurrentImageIndex(index);
    }
  }, [currentImageIndex, images.length, random]);

  useEffect(() => {
    const timer = setTimeout(() => {
      nextSlide();
    }, 8000); // 8 seconds

    return () => {
      clearTimeout(timer);
    };
  }, [currentImageIndex, nextSlide]);

  return (
    <div className="carousel">
      {showButtons && (
        <button className="carousel-button carousel-prev" onClick={prevSlide}>
          Prev
        </button>
      )}
      <img
        className="carousel-image img-fluid rounded shadow-lg"
        src={images[currentImageIndex]}
        alt={`Slide ${currentImageIndex}`}
      />
      {showButtons && (
        <button className="carousel-button carousel-next" onClick={nextSlide}>
          Next
        </button>
      )}
    </div>
  );
};

export default ImageCarousel;
