// NoticeSec.js
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./styles/NoticeSec.css";

const NoticeSec = () => {
  return (
    <Container fluid className="notice-container p-3 mt-3">
      <Row>
        <Col xs={12}>
          <p className="notice-text">
            Join us for our regular classes on 
            <span className="highlight"> Friday </span>
            at 
            <a href="https://aikido-dojo.org/" target="_blank" rel="noopener noreferrer"> Northeast Aikido </a> 
            located at <br /><span className="location-placeholder"> 326 South Broadway Rt. 28 (unit L) Salem NH 03079 </span><br />
            <span className="time-placeholder">6:30 to 8:00 pm</span>
          </p>
          <p className="additional-info">
            Students are welcome to attend other classes the rest of the week led by Northeast Aikido.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default NoticeSec;
