// RoutesContainer.js
import React from "react";
import { useLocation } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/RoutesContainer.css";
import NoticeSec from "../Components/NoticeSec";
import WelcomeSec from "../Components/WelcomeSec";
import WhatIsAikidoSec from "../Components/WhatIsAikidoSec";
import AboutUsSec from "../Components/AboutUsSec";
import ClassesSec from "../Components/ClassesSec";
import Classes from "../Pages/Classes";
import AboutUs from "../Pages/AboutUs";
import FAQs from "../Pages/FAQs";
import Contact from "../Pages/Contact";
import Schedule from "./Schedule";
import ImageCarousel from "../Components/ImageCarousel";

const homepageImages = require.context(
  "../assets/homepage",
  false,
  /\.(jpeg|jpg|png)$/
);
const otherpageImages = require.context(
  "../assets/pictures",
  false,
  /\.(jpeg|jpg|png)$/
);

const getImageArray = (imagesContext) => {
  const imagePaths = imagesContext.keys();
  return imagePaths.map((path) => imagesContext(path));
};

const imageArray = getImageArray(homepageImages);
const imageArray_2 = getImageArray(otherpageImages);

const RoutesContainer = () => {
  const location = useLocation();
  const isHomepage = location.pathname === "/";

  return (
    <Container
      fluid
      className={isHomepage ? "homepage-container" : "otherpages-container p-0"}
    >
      <main>
        <Routes>
          <Route
            path="/"
            element={
              <React.Fragment>
                <NoticeSec />
                <Row>
                  <Col xs={12}>
                    <h2
                      className="text-center py-3"
                      style={{ fontWeight: "bold" }}
                    >
                      Welcome to Granite State Aikido Club

                    </h2>
                    <p
                      className="lead text-center"
                      style={{ fontSize: "1.5em" }}
                    >
                      <a href="https://www.usaikifed.com/">Member of United States Aikido Federation</a> <br />
                      Discover the traditional Japanese martial art of Aikido
                      right here in New Hampshire.
                    </p>
                  </Col>
                </Row>
                <Row className="homepage-content align-items-start py-2 mx-0">
                  <Col xs={12} md={4}>
                    <WelcomeSec />
                  </Col>
                  <Col xs={12} md={4}>
                    <ImageCarousel images={imageArray} showButtons={false} random={true}/>
                  </Col>
                  <Col xs={12} md={4}>
                    <AboutUsSec />
                  </Col>
                </Row>
                <Row className="homepage-content align-items-start py-2 mx-0">
                  <Col xs={12} md={4}>
                    <WhatIsAikidoSec />
                  </Col>
                  <Col xs={12} md={4}>
                    <ImageCarousel images={imageArray_2} showButtons={false} random={true}/>
                  </Col>
                  <Col xs={12} md={4}>
                    <ClassesSec />
                  </Col>
                </Row>
              </React.Fragment>
            }
          />
          <Route path="/classes" element={<Classes />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/FAQs" element={<FAQs />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/schedule" element={<Schedule />} />
          {/* Add more routes as needed */}
          {/* ... (other routes) */}
        </Routes>
      </main>
    </Container>
  );
};

export default RoutesContainer;
