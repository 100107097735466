// Schedule.js
import React from 'react';
import { Container, Row, Col, Table } from 'react-bootstrap';
import './styles/Schedule.css';

const Schedule = () => (
  <Container className="schedule-container mt-3 mb-3">
    <Row>
      <Col className="text-center">
        <h2 className="schedule-title">Class Schedule</h2>
      </Col>
    </Row>
    <Row>
      <Col>
        <Table responsive bordered hover>
          <thead>
            <tr>
              <th>Day</th>
              <th>Time</th>
              <th>Class</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Friday</td>
              <td>6:30pm - 8:00pm</td>
              <td>Aikido Class</td>
            </tr>
          </tbody>
        </Table>
      </Col>
    </Row>
  </Container>
);

export default Schedule;