import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./styles/Footer.css";

const Footer = () => {
  return (
    <footer className="text-white py-1">
      <Container>
        <Row>
          <Col md={4} className="py-2">
            <p>
              Contact us to learn more about our classes, schedule a visit, or
              try a free class. We're excited to welcome you to our Aikido
              community.
            </p>
          </Col>
          <Col md={4} className="py-2">
            <p style={{ textAlign: "center", fontSize : "1.4em" }}>
              Email: <a href="mailto:mhovan4@mac.com">mhovan4@mac.com</a>
            </p>
          </Col>
          <Col md={4} className="d-flex justify-content-around py-2">
            <a href="https://www.instagram.com" className="text-white">
              <i className="fab fa-instagram"></i>
            </a>
            <a href="https://www.facebook.com" className="text-white">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a href="https://www.twitter.com" className="text-white">
              <i className="fab fa-twitter"></i>
            </a>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
