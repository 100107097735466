import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./styles/WhatIsAikidoSec.css";
import "bootstrap/dist/css/bootstrap.min.css";

const WhatIsAikidoSec = () => {
  return (
    <section className="what-is-aikido-sec">
      <Container>
        <Row>
          <Col>
            <h2 className="text-center mb-4" style={{ fontWeight: "bold" }}>
              What is Aikido
            </h2>
            <p className="lead" style={{ fontSize: "1.4em" }}>
              Aikido is a martial art that teaches you how to use the momentum
              of an attacker against themselves. It involves pins, throws, and
              joint locks, taught as katas, or forms. Aikido can be practiced by
              anyone regardless of size, age, or gender.
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default WhatIsAikidoSec;
