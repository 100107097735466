// FAQs.js
import React from "react";
import { Container, Row, Col } from "react-bootstrap"; // Import Container, Row, and Col from react-bootstrap
import "./styles/FAQs.css";

const FAQs = () => {
  return (
    <Container className="faqs-container">
      <Row>
        <Col>
          <h1 className="display-4">Frequently Asked Questions</h1>
        </Col>
      </Row>
      <Row className="faq my-4">
        <Col>
          <h2 className="display-5">I am a beginner, can I join?</h2>
          <p className="lead">
            We gladly welcome beginners, people from other styles, and people of
            all ages and body types. Anyone can learn Aikido, it just takes a
            willingness to try. Please come in comfortable clothes you can move
            in, or wear a gi with an appropriate belt (white or black). Upon
            your arrival, you will have to sign a liability waiver, but after
            that you can jump right in. Beginners and Advanced students work
            with each other in class.
          </p>
        </Col>
      </Row>
      <Row className="faq my-4">
        <Col>
          <h2 className="display-5">What is a typical practice like?</h2>
          <p className="lead">
            Classes usually begin with warm ups and stretches. Most of class is
            partner practice or group practice, but how hard you train is really
            a matter of how much you want or are able to put into it. Classes
            are non-competitive. The essence of Aikido is cooperation and
            respect. This philosophy is expressed through Aikido’s unique use of
            throws and locks. All students at any level build upon a strong
            foundation of basics.
          </p>
        </Col>
      </Row>
      {/* ... other FAQs */}
      <Row className="faq my-4">
        <Col>
          <h2 className="display-5">What is the Ranking System?</h2>
          <p className="lead">
            We follow the United States Aikido Federation (USAF) curriculum.
            Students officially enrolled with Granite State Aikido Club will be
            registered with the USAF, and will be able to achieve rank through
            the USAF that is recognized in all USAF dojos.
          </p>
        </Col>
      </Row>
      <Row className="faq my-4">
        <Col>
          <h2 className="display-5">What is Aikido?</h2>
          <p className="lead">
            AIKIDO – The name Aikido is composed of three Japanese characters:
            Ai, meaning harmony; Ki, spirit or energy; and Do, path or way.
            Aikido is The Way of Spiritual Harmony.
          </p>
          <p className="lead">
            Aikido is a Japanese martial art created in the early 20th century
            by Morihei Ueshiba and teaches effective self defense in a unique
            way. An Aikido practitioner will blend or harmonize with an attack
            and use dynamic circular movements to unbalance the attacker.
            Aikidoist learn to apply an endless variety of joint-locks, pins, or
            unbalancing throws to render an attacker harmless without causing
            serious injury.
          </p>
          {/* ... other paragraphs */}
          <p className="lead">
            Aikido is not only an effective form of self defense but also an
            effective way to lose weight, increase strength & flexibility, and
            improve overall fitness and health; all while learning a traditional
            martial art. Aikido training also offers many internal benefits such
            as decreased stress, increased focus, higher self-confidence, and
            better self-discipline. Aikido training allows you to experience a
            profound, life-changing truth: Strength comes from relaxation.
            Aikido teaches you what it means to move from your center and puts
            you in touch with the true source of your strength, which has much
            more to do with your mind than your muscles. Students, whether a
            beginner or highly experienced, train at their own pace and ability
            level. All it takes from you is a commitment to show up and a
            willingness to learn.
          </p>
        </Col>
      </Row>
      <Row className="faq my-4">
        <Col>
          <h2 className="display-5">
            Can I book you to do a workshop or a demonstration?
          </h2>
          <p className="lead">
            Yes, depending on our availability, we can give a demo for school
            groups, companies, festivals or other events.
          </p>
        </Col>
      </Row>

      <Row className="faq my-4">
        <Col>
          <h2 className="display-5">
            Where can I find more information about Aikido?
          </h2>
          <p className="lead">
            Visit the United State Aikido Federation’s Website for history,
            current events, articles, and upcoming events and seminars. A link
            to the Aikido student handbook will be posted soon.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default FAQs;
