import React from "react";
import "./styles/ClassesSec.css";

const ClassesSec = () => {
    return (
        <section className="ClassesSec">
        <h2 style={{ textAlign: "center", fontWeight : "bold" }}>Classes</h2>
        <p>
            Our Aikido classes are designed to teach you how to use the
            momentum of an attacker against themselves. Learn the art of
            Aikido through pins, throws, and joint locks, taught as katas, or
            forms. We offer classes for both adults and children, ensuring
            everyone can experience the benefits of Aikido.
        </p>
        </section>
    );
};

export default ClassesSec;


