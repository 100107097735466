// App.js
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Hero from "../Components/Hero";
import RoutesContainer from "./RoutesContainer";
import "./App.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container } from 'react-bootstrap';

const App = () => {
  return (
    <Router>
      <Container fluid className="d-flex flex-column min-vh-100">
        <Header />
        <Hero />
        <RoutesContainer />
        <Footer />
      </Container>
    </Router>
  );
};

export default App;
